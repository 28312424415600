<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-row>
         <v-col cols="12" sm="6" md="4" v-for="(elem, indx) in $store.state.public_wods" :key="indx">
            <v-card dark>
               <v-toolbar>
                  {{ elem.nombre }}
                  <v-icon right color="warning" v-if="elem.tipo == 'T'">mdi-clock-outline</v-icon>
                  <v-icon right color="success" v-else>mdi-repeat-variant</v-icon>
               </v-toolbar>
               <v-img height="400px" :src="$c.API_URL + '/public/' + elem.img"></v-img>
            </v-card>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   data() {
      return {
         headers: [
            { text: "Nombre", value: "nombre" },
            { text: "Tipo", value: "tipo" },
         ],
         isloading: true,
         search: "",
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getPublicWods");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style></style>
