<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title class="d-flex justify-end">
                <v-btn text rounded @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <h1 v-if="!found" class="text-center">No se encontro la imagen de la puntuación</h1>
                <img :src="$c.API_URL + '/' + imageToShow" alt="" ref="imagePreview">
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            imageToShow: "",
            found: false,
        }
    },
    methods: {
        close() {
            this.dialog = false;
            this.imageToShow = "";
            this.found = false;
        },
        notFound() {
            this.found = false;
            this.$refs.imagePreview.src = "";
        },
        showPhoto() {
            this.found = true;
        }
    },
};
</script>

<style scoped>
canvas {
    border: 1px solid red;
}
</style>