<template>
   <div>
      <div :class="hide_show">
         <v-img class="img" :src="img"></v-img>
         <div class="d-flex justify-en align-center">
            <div v-for="(item, index) in links" :key="index" class="mr-2" style="color: white">
               <v-icon color="white" @click="$vuetify.goTo(item.target, options)">{{ item.icon }}</v-icon> {{ item.name }}
            </div>
         </div>
      </div>
      <v-btn class="login-btn" color="#10385e" icon @click="$router.push({ name: 'Login' }, () => {})"><v-icon>mdi-cog</v-icon></v-btn>
   </div>
</template>

<script>
export default {
   data: () => {
      return {
         last: 0,
         show: true,
         img: require("../../assets/logo1.png"),
         links: [
            {
               name: "Wods",
               target: "#wods",
               icon: "mdi-clipboard-clock-outline",
            },
            {
               name: "Atletas",
               target: "#atletas",
               icon: "mdi-weight-lifter",
            },
            {
               name: "Puntuaciones",
               target: "#puntuaciones",
               icon: "mdi-podium",
            },
            {
               name: "Generales",
               target: "#generales",
               icon: "mdi-medal",
            },
            {
               name: "Nosotros",
               target: "#nosotros",
               icon: "mdi-account-group",
            },
         ],
         options: {
            duration: 1000,
            offset: 0,
         },
      };
   },
   created() {
      window.addEventListener("scroll", this.handleScroll);
   },
   destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
   },
   methods: {
      handleScroll(event) {
         if (window.scrollY >= this.last) {
            this.show = false;
         } else {
            this.show = true;
         }
         this.last = window.scrollY;
      },
   },
   computed: {
      hide_show: function () {
         return this.show ? "top show d-none d-sm-flex" : "top d-none d-sm-flex";
      },
   },
};
</script>

<style scoped>
.top {
   z-index: 9998;
   position: fixed;
   top: -150px;
   height: 150px;
   width: 100%;
   background-color: #15497a;
   clip-path: polygon(0 0, 0 100%, 35% 80%, 100% 100%, 100% 0);
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   transition: all ease-in-out 200ms;
   padding: 16px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.show {
   top: 0;
   transition: all ease-in-out 200ms;
}

.img {
   max-width: 180px;
}

.login-btn{
   position: absolute;
   top: 0;
   right: 0;
   z-index: 9999;
}
</style>
