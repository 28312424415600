<template>
   <div class="separator" :style="style_type">{{ titulo }}</div>
</template>

<script>
export default {
   props: {
      titulo: String,
      tipo: {
         type: Number,
         default: 0,
      },
      color: {
         type: String,
         default: "#c1292e",
      },
   },
   data: () => {
      return {
         top: "",
      };
   },
   methods: {},
   computed: {
      style_type: function () {
         let str = "";
         if (this.tipo == 0) {
            str = "clip-path: polygon(0 0, 100% 0, 100% 100%, 81% 84%, 51% 93%, 24% 85%, 0 100%);";
         } else if (this.tipo == 1) {
            str = "clip-path: polygon( 0 0, 22% 12%, 62% 3%, 86% 9%, 100% 0, 100% 100%, 76% 90%, 37% 98%, 20% 94%, 0 100% );";
         } else if (this.tipo == 2) {
            str = "clip-path: polygon( 0 0, 39% 11%, 66% 3%, 85% 14%, 100% 0, 100% 100%, 86% 85%, 63% 95%, 28% 88%, 0 100% );";
         } else if (this.tipo == 3) {
            str = "clip-path: polygon( 0 0, 14% 11%, 43% 4%, 78% 8%, 100% 0, 100% 100%, 88% 86%, 43% 95%, 20% 84%, 0 100% );";
         } else if (this.tipo == 4) {
            str = "clip-path: polygon( 0 0, 22% 12%, 62% 3%, 86% 9%, 100% 0, 100% 100%, 76% 90%, 37% 98%, 20% 94%, 0 100% );";
         } else if (this.tipo == 5) {
            str = "clip-path: polygon( 34% 5%, 51% 13%, 70% 5%, 89% 9%, 100% 0, 100% 100%, 0 100%, 0 0, 15% 12% );";
         }
         str += "background-color: " + this.color + ";";
         return str;
      },
   },
};
</script>

<style scoped>
.separator {
   width: 100%;
   height: 150px;
   background-color: #c1292e;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 45px;
   font-weight: bold;
   color: white;
}
</style>
