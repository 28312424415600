<template>
   <div>
      <Main/>
   </div>
</template>

<script>
import Main from "../components/Main/Main.vue"

export default {
   components:{
      Main
   }
};
</script>

<style></style>
