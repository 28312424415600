<template>
    <v-dialog width="unset" v-model="dialog" class="top-card">
        <v-card>
            <v-card-title class="d-flex justify-center">
                <div>
                    {{ item.nombre }}
                </div>
                <div>
                    <v-btn text rounded @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field outlined label="Gym" disabled v-model="item.gym">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field disabled outlined label="Rango" class="pretty_title" v-model="rango">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <v-data-table :headers="headers" :items="items">
                        <template #[`item.wod_name`]="{ item }">
                            <button @click="() => openPreviewPhoto(item)">
                                {{ item.wod_name }}
                            </button>
                        </template>
                        <template #[`item.categoria`]>
                            <v-icon :color="colorCategoria">
                                {{ categoria }}
                            </v-icon>
                        </template>
                        <template #[`item.tipo`]="{ item }">
                            <div v-if="item.tipo === 'T'">
                                <v-icon small color="warning">mdi-clock-outline</v-icon>
                                {{ secondsToMinutes(item.value) }}
                            </div>
                            <div v-else>
                                <v-icon small color="success">mdi-repeat-variant</v-icon>
                                {{ item.value }}
                            </div>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
        </v-card>
        <PreviewPhoto ref="previewPhoto" />
    </v-dialog>
</template>

<script>
import PreviewPhoto from '../Puntuaciones/Puntuacion/PreviewPhoto.vue';

function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

export default {
    components: {
        PreviewPhoto,
    },
    data() {
        return {
            dialog: false,
            nombre: '',
            item: {},
            items: [],
            headers: [
                { text: 'Wod', value: 'wod_name', align: 'center' },
                { text: 'Categoria', value: 'categoria', align: 'center' },
                { text: 'Tipo', value: 'tipo', align: 'center' },
                { text: 'Score', value: 'score', align: 'center' },
            ]
        };
    },
    methods: {
        close() {
            this.dialog = false;
        },
        async openPreviewPhoto({ atleta_id, wod_name }) {
            this.$refs.previewPhoto.dialog = true;
            const response = await this.$store.dispatch("getImageGeneral", {
            atleta_id,
            wod_name
            });

            if (
            response.data === "" 
            || response.data === undefined
            || response.data === null
            ) {
            this.$refs.previewPhoto.notFound();
            return;
            }

            this.$refs.previewPhoto.imageToShow = response.data.replaceAll("./", "");
            this.$refs.previewPhoto.showPhoto();
        },
        secondsToMinutes(time) {
            let minutes = Math.floor(time / 60);
            let seconds = time - minutes * 60;
            return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
        },
    },
    computed: {
        rango: function () {
            if (this.item.rango === 'R') return 'RX'; 
            if (this.item.rango === 'A') return 'Avanzado'; 
            if (this.item.rango === 'M') return 'Master';
            if (this.item.rango === 'I') return 'Intermedio';
            if (this.item.rango === 'K') return 'Kids';

            return 'Principiante';
        },
        categoria: function () {
            if (this.item.categoria === 'F') return 'mdi-face-woman';
            return 'mdi-face-man';
        },
        colorCategoria: function () {
            if (this.item.categoria === 'F') return 'pink';
            return 'blue';
        },
    },
    watch: {
        dialog: async function () {
            if (!this.dialog) return;
            const data = await this.$store.dispatch('getAtletaInfo', {
                num: this.item.num
            });
            if (data.status === 400) {
                console.log('hubo un problema');
                return;
            }
            this.items = [...data];
        }
    }
};
</script>

<style scoped>
.pretty_title {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.top-card {
    z-index: 10000;
}
</style>