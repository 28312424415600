<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="$store.state.public_atletas"
         :items-per-page="10"
         class="elevation-1 mb-8"
         :search="search"
      >
         
         <template v-slot:top>
            <v-toolbar flat>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
         <template v-slot:[`item.num`]="{ item }">
            <div class="pretty_title_2">{{ item.num }}</div>
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
      </v-data-table>
   </div>
</template>

<script>

export default {
   data() {
      return {
         headers: [
            { text: "Nombre", value: "nombre" },
            { text: "Num", value: "num" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Gym", value: "gym" },
         ],
         isloading: true,
         search: "",
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getPublicAtletas");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style scoped>
.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
.pretty_title_2 {
   font-size: 12px;
   font-weight: 800;
   letter-spacing: 2px;
}
</style>
