<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-data-table
         :loading="isloading"
         :headers="headers"
         :items="items"
         :items-per-page="10"
         class="elevation-1 mb-8"
         :search="search"
      >
         <template v-slot:top>
            <v-toolbar flat>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  label="Rango"
                  item-text="text"
                  item-value="value"
                  clearable
                  :items="rangos"
                  v-model="rango"
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  label="Categoria"
                  item-text="text"
                  item-value="value"
                  clearable
                  :items="categorias"
                  v-model="categoria"
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  label="Wod"
                  item-text="nombre"
                  item-value="nombre"
                  v-model="wodFilter"
                  :items="wods"
                  clearable
               ></v-select>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
         <template v-slot:[`item.tipo`]="{ item }">
            <div v-if="item.tipo == 'T'">
               <v-icon small color="warning">mdi-clock-outline</v-icon>
               {{ secondsToMinutes(item.value) }}
            </div>
            <div v-else>
               <v-icon small color="success">mdi-repeat-variant</v-icon>
               {{ item.value }}
            </div>
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
function str_pad_left(string, pad, length) {
   return (new Array(length + 1).join(pad) + string).slice(-length);
}

export default {
   data() {
      return {
         headers: [
            { text: "Wod", value: "wod" },
            { text: "Atleta", value: "atleta" },
            { text: "Gym", value: "gym" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Tipo", value: "tipo" },
            { text: "Score", value: "score" },
         ],
         isloading: true,
         search: "",
         items: [],
         rango: null,
         categoria: null,
         wodFilter: null,
         wods: this.$store.state.public_wods,
         categorias: [
            {
               value: "F",
               text: "Femenino",
            },
            {
               value: "V",
               text: "Varonil",
            },
         ],
         rangos: [
            {
               value: "P",
               text: "Principiante",
            },
            {
               value: "I",
               text: "Intermedio",
            },
            {
               value: "M",
               text: "Master",
            },
            {
               value: "A",
               text: "Avanzado",
            },
            {
               value: "R",
               text: "RX",
            },
            {
               value: "K",
               text: "Kids",
            },
         ],
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getPublicPuntuaciones");
            let items = this.$store.state.public_puntuaciones;
            this.wods = this.$store.state.public_wods;

            if (this.categoria)
               items = items.filter(elem => elem.categoria === this.categoria);

            if (this.rango)
               items = items.filter(elem => elem.rango === this.rango);

            if (this.wodFilter)
               items = items.filter(elem => elem.wod === this.wodFilter);
            
            this.items = items;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      secondsToMinutes(time) {
         let minutes = Math.floor(time / 60);
         let seconds = time - minutes * 60;
         return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      },
   },
   created() {
      this.initialize();
   },
   watch: {
      categoria: function () {
         this.initialize();
      },
      rango: function () {
         this.initialize();
      },
      wodFilter: function () {
         this.initialize();
      }
   },
};
</script>

<style scoped>
.my-select {
   max-width: 200px;
}

.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
</style>
