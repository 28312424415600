<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <AtletaRecord ref="atletaRecord" />
      <v-data-table :loading="isloading" :headers="headers" :items="items" :items-per-page="10" class="elevation-1 mb-8"
         :search="search"
      >
         <template v-slot:top>
            <v-toolbar flat>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="rango"
                  label="Rango"
                  :items="rangos"
                  item-text="text"
                  item-value="value"
                  clearable
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="categoria"
                  label="Categoria"
                  :items="categorias"
                  item-text="text"
                  item-value="value"
                  clearable
               ></v-select>
               <v-text-field v-model="search" label="Buscar" clearable hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template #[`item.nombre`]="{ item }">
            <button href="" class="text-decoration-none" @click="() => atletaRecord(`${item.nombre}`, item)">
               {{ item.nombre }}
            </button>
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
         <template v-slot:[`item.score`]="{ item }">
            <div class="pretty_title_2">{{item.score}}</div>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import AtletaRecord from './AtletaRecord.vue';

export default {
   components: {
      AtletaRecord,
   },
   data() {
      return {
         isloading: true,
         headers: [
            { text: "Posición", value: "posicion" },
            { text: "Atleta", value: "nombre" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Gym", value: "gym" },
            { text: "Score", value: "score" },
         ],
         items: [],
         search: "",
         categoria: null,
         rango: null,
         categorias: [
            {
               value: "F",
               text: "Femenino",
            },
            {
               value: "V",
               text: "Varonil",
            },
         ],
         rangos: [
            {
               value: "P",
               text: "Principiante",
            },
            {
               value: "I",
               text: "Intermedio",
            },
            {
               value: "M",
               text: "Master",
            },
            {
               value: "A",
               text: "Avanzado",
            },
            {
               value: "R",
               text: "RX",
            },
            {
               value: "K",
               text: "Kids",
            },
         ],
      };
   },
   watch: {
      categoria: function () {
         this.initialize();
      },
      rango: function () {
         this.initialize();
      },
      items: function () {
         let pos = 1;
         let skip = 1;
         let scorePre = this.items[0]?.score;
         for (let i = 1; i < this.items.length; i++) {
            if (this.items[i].score !== scorePre) {
               pos = pos + skip;
               scorePre = this.items[i].score;
               this.items[i].posicion = pos;
               skip = 1;
               continue;
            }
            this.items[i].posicion = pos;
            skip++;
         }
      },
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getPublicGenerales");
            let items = this.$store.state.public_generales

            if(this.categoria){
               items = items.filter(elem => elem.categoria == this.categoria)
            }
            if(this.rango){
               items = items.filter(elem => elem.rango == this.rango)
            }

            let i = 0;
            items.map((elem) => {
               i++;
               elem.posicion = i;
            })

            this.items = items;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      atletaRecord(nombre, item) {
         this.$refs.atletaRecord.nombre = nombre;
         this.$refs.atletaRecord.dialog = true;
         const num = item.nombre.split('[')[1].split(']')[0];
         item.num = num;
         this.$refs.atletaRecord.item = item;
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style scoped>
.my-select {
   max-width: 200px;
}
.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
.pretty_title_2 {
   font-size: 12px;
   font-weight: 800;
   letter-spacing: 2px;
}
</style>
