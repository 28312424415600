<template>
  <v-carousel
    cycle
    height="100vh"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="(img, i) in imgs"
      :key="i"
    >
      <v-img :src="img" height="100vh" width="100%"></v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        imgs: [
            require("../../assets/img1.jpg"),
            require("../../assets/img2.jpg"),
            require("../../assets/img3.jpg"),
            require("../../assets/img4.jpg"),
            require("../../assets/img5.jpg"),
        ],
      }
    },
  }
</script>

<style>

</style>