<template>
   <div>
      <Top />
      <Carousel />
      <Separator titulo="WODS" id="wods" :tipo="0" />
      <Wods />
      <Separator titulo="Atletas" id="atletas" :tipo="1" />
      <Atletas />
      <Separator titulo="Puntuaciones" id="puntuaciones" :tipo="2" />
      <Puntuaciones />
      <Separator titulo="Generales" id="generales" :tipo="1" />
      <Generales />
      <Separator titulo="Nosotros" id="nosotros" :tipo="4" />
      <div>
         <v-row>
            <v-col cols="12" md="6">
               <v-card class="ma-6" elevation="0">
                  <v-card-title>
                     <v-spacer></v-spacer>
                     ¿Quiénes somos?
                     <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                     Somos un grupo de entusiastas por el deporte y la competencia, enfocados en hacer crecer la comunidad de crossfit en el pais
                     convencidos de que la sana competencia es la mejor manera de elevar el nivel como atleta
                  </v-card-text>
               </v-card>
            </v-col>
            <v-col cols="12" md="6">
               <v-card class="ma-6" elevation="0">
                  <v-card-title>
                     <v-spacer></v-spacer>
                     Objetivo
                     <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                     Consolidarnos como la competencia mas grande y reconocida de crossfit del norte del pais con base al gran nivel y profesionalismo
                     de nuestro evento. impulsando el talento tanto local como nacional formando una comunidad fuerte y solida de crossfit, hacien
                  </v-card-text>
               </v-card>
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.264501938897!2d-106.89994000000002!3d28.4112747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86c1ca6dfa0bdb57%3A0x31e39e5c0ba8307a!2sInstituto%20Tecnol%C3%B3gico%20de%20Cd.%20Cuauht%C3%A9moc!5e0!3m2!1ses!2smx!4v1719595550678!5m2!1ses!2smx"
                  width="100%"
                  class="mb-8"
                  height="500px"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
               ></iframe>
            </v-col>
         </v-row>
      </div>
      <Separator titulo="3CCHALLENGE" :tipo="5" color="#14213dff" />
   </div>
</template>

<script>
import Top from "./Top.vue";
import Carousel from "./Carousel.vue";
import Separator from "./Separator.vue";
import Wods from "./Wods.vue";
import Atletas from "./Atletas.vue";
import Puntuaciones from "./Puntuaciones.vue";
import Generales from "./Generales.vue";

export default {
   components: {
      Top,
      Separator,
      Carousel,
      Wods,
      Atletas,
      Puntuaciones,
      Generales,
   },
};
</script>

<style></style>
